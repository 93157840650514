(function() {

  'use strict';
  /* global angular */

  angular
    .module("Milestone.findYourHome")

    .controller("FindYourHomeCtrl", ["$scope", "$timeout", "CommunitiesListDataService", "UserDataService", "$http", "$rootScope", "$routeParams", "$location",
      function($scope, $timeout, CommunitiesListDataService, UserDataService, $http, $rootScope, $routeParams, $location) {

        $scope.tab = 'communities';
        $scope.routeParams = $routeParams;
        $scope.seo_name = $scope.routeParams.seoName;

        $scope.regions = CommunitiesListDataService.regions;

        $scope.mobileCommunity = function(){
          $location.path('/find-your-home/' + $scope.seo_name);
        }

        $scope.activeRegion = function() {
          var res = $scope.regions.filter(function(region) {
            return region.seo_name === $scope.seo_name;
          });
          return res[0];
        };

        $scope.communityListOpened = false;

        $scope.toggleCommunityList = function() {
          $scope.communityListOpened = !$scope.communityListOpened;
        };

        $scope.selectedTabIndex = 0;

        $scope.selectTab = function(tabIndex) {
          $scope.selectedTabIndex = tabIndex;
        };

        $scope.naturalSort = function(community) {
          return community.name.replace(/^The /, '');
        };

        $scope.sortColumn = $scope.naturalSort;
        $scope.reverse = false;

        // got to use this instead of == to support arrays
        var array_equals = function(a, b) {
          // if !a XOR !b then we can assume false
          /*jslint bitwise: true */
          if ( (a === undefined ? 1 : 0) ^ (b === undefined ? 1 : 0) ) {
            return false;
          }

          if (a instanceof Function){
            return a === b;
          }

          if (typeof a === 'string') {
            return a === b;
          }

          return a.length === b.length && a.every(function(v, i) {
              return v === b[i];
            });
        };

        $scope.sort = function(eq, column) {

          if (array_equals($scope.sortColumn, column)) {

            if (eq % 2 === 0 && $rootScope.is_phone) {
              $scope.reverse = false;
            } else if ($rootScope.is_phone) {
              $scope.reverse = true;
            } else {
              $scope.reverse = !$scope.reverse;
            }

            return;
          }

          $scope.sortColumn = column;
          $scope.reverse = false;
        };

        $scope.mobileSort = function(selected) {
          $scope.sortColumn = selected.filterBy;
          $scope.reverse = selected.sort === 'asc' ? false : true;
        };

        $scope.sortArrows = function(column) {
          var asc = array_equals($scope.sortColumn, column) && !$scope.reverse;
          var desc = array_equals($scope.sortColumn, column) && $scope.reverse;

          var res = {
            'indicator': true,
            'indicator-asc': asc,
            'indicator-desc': desc
          };

          return res;
        };

        $scope.dropdownList = [
          {
            filterName: 'Community Name (A-Z)',
            filterBy: 'name',
            sort: 'asc'
          },
          {
            filterName: 'Community Name (Z-A)',
            filterBy: 'name',
            sort: 'desc'
          },
          {
            filterName: 'Price (low to high)',
            filterBy: 'price',
            sort: 'asc'
          },
          {
            filterName: 'Price (high to low)',
            filterBy: 'price',
            sort: 'desc'
          },
          {
            filterName: 'Sqft (low to high)',
            filterBy: ['sqft_low', 'sqft_high'],
            sort: 'asc'
          },
          {
            filterName: 'Sqft (high to low)',
            filterBy: ['sqft_low', 'sqft_high'],
            sort: 'desc'
          },
          {
            filterName: 'Bed (low to high)',
            filterBy: ['bed_low', 'bed_high'],
            sort: 'asc'
          },
          {
            filterName: 'Bed (high to low)',
            filterBy: ['bed_low', 'bed_high'],
            sort: 'desc'
          },
          {
            filterName: 'Bath (low to high)',
            filterBy: ['bath_low', 'bath_high'],
            sort: 'asc'
          },
          {
            filterName: 'Bath (high to low)',
            filterBy: ['bath_low', 'bath_high'],
            sort: 'desc'
          }
        ];

        $scope.regionSort = $scope.dropdownList[0];



        $scope.signup = {};
        UserDataService.getUser().then(function (user) {
          $scope.signup = angular.copy(user);
          $scope.signup.origin = window.location.href;
          $scope.signup.type = 'signUpMicrosite';
          $scope.signup.communities = [];
          //$scope.signup.communities[0] = $scope.community.id;
          $scope.signup.form_id = 177545;
          $scope.signup.guid = window.getLassoGuid();
        });

        $scope.hideForm = false;
        $scope.showMessage = false;
        $scope.showLoader = false;
        $scope.triedSignupSubmit = false;

        $scope.checkSignupFormValidation = function (form) {
          $scope.triedSignupSubmit = true;

          if (!form.$valid) {
            return;
          }

          processQuickSignUpForm();
        };

        var processQuickSignUpForm = function () {
          $scope.hideForm = true;
          $scope.showLoader = true;
          $http({
            method: 'POST',
            url: '/form/to-database',
            data: $.param($scope.signup), // pass in data as strings
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            } // set the headers so angular passing info as form data (not request payload)
          }).success(function (data) {
            $scope.showLoader = false;
            if (data.status === 'success') {
              // Tie lead id and email to clicky analytics data
              clicky_custom.visitor = {
                lead_id: data.id,
                email: data.email,
                signup: '1'
              };

              clicky_custom.goal = {
                name: 'Quick Signup'
              };

              // Log action in clicky
              clicky.goal('2672'); // Logs a "quick-signup" goal hit in clicky
              clicky.log('/quick-signup/thank-you'); // Logs a fake page hit in clicky

              // Fire off a Facebook Pixel Code (Lead)
              fbq('track', 'Lead');

              // Fire off a Google Analytics goal hit
              _gaq.push(['_trackPageview', '/ajax-goals/quick-signup-microsite']);

              if (typeof googleConversionId !== 'undefined' && typeof googleConversionLabel !== 'undefined' && googleConversionId && googleConversionLabel) {
                $window.google_trackConversion({
                  google_conversion_id: googleConversionId,
                  google_conversion_language: 'en',
                  google_conversion_format: '3',
                  google_conversion_color: 'ffffff',
                  google_conversion_label: googleConversionLabel,
                  google_remarketing_only: false
                });
              }

              $scope.message = data.message;
              $scope.showMessage = true;
            } else {
              $scope.message = data.message;
              $scope.showMessage = true;
              $scope.hideForm = false;
            }
          });
        };

      }
    ])

    .controller('FindYourHomeMapCtrl', ['$scope', '$q', 'uiGmapPromises', 'RegionDataPromiseService', '$timeout', 'CommunitiesListDataService',
      function($scope, $q, uiGmapPromises, RegionDataPromiseService, $timeout, CommunitiesListDataService) {

        $scope.tab = 'map';

        $scope.regions = CommunitiesListDataService.regions;


        uiGmapPromises.GoogleMapsApiReady()
          .then(function init(GoogleMaps) {

            $scope.map = {
              center: {
                latitude: 30.262,
                longitude: -97.934
              },
              control: {},
              markers: [],
              zoom: 10,
              options: {
                streetViewControl: false,
                draggable: true,
                scrollwheel: true,
                mapTypeControl: false,
                panControl: true,
                panControlOptions: {
                  position: GoogleMaps.ControlPosition.RIGHT_CENTER
                },
                zoomControl: true,
                zoomControlOptions: {
                  position: GoogleMaps.ControlPosition.RIGHT_CENTER
                }
              },
              markersEvents: {
                click: function(marker, eventName, model, args) {
                  $scope.map.window.coords = marker.center;
                  $scope.map.window.show = false;
                  $scope.map.window.marker = marker;
                  $scope.map.window.model = model;
                  $scope.map.window.show = true;
                }
              },
              window: {
                marker: {},
                show: false,
                closeClick: function() {
                  this.show = false;
                  this.model = {};
                },
                options: {
                  maxWidth: 800
                }
              }
            };

          });



        var fitBoundsWithCurrentMarkers = function(theMap, GoogleMaps) {

          GoogleMaps.event.trigger(theMap, 'resize');

          var newBounds = new GoogleMaps.LatLngBounds();

          angular.forEach($scope.map.markers, function(marker) {
            newBounds.extend(new GoogleMaps.LatLng(marker.latitude, marker.longitude));
          });

          theMap.fitBounds(newBounds);

          if (theMap.getZoom() > 11) {
            theMap.setZoom(11);
          }
        };

        RegionDataPromiseService
          .promise
          .then(uiGmapPromises.GoogleMapsIsAllReady)
          .then(function addMarkers() {
            angular.forEach($scope.regions, function(region) {

              angular.forEach(region.communities, function(community) {

                var marker = {
                  id: community.id,
                  name: community.name,
                  latitude: community.latitude,
                  longitude: community.longitude,
                  community: community,
                  icon: '/images/site/blue-icon-pin.png',
                  options: {
                    visible: true
                  }
                };

                $scope.map.markers.push(marker);

              });
            });
          })
          .then(function() {

            $timeout(function() {

              // re-size and re-center map
              uiGmapPromises.GoogleMapsApiReady()
                .then(function init(GoogleMaps) {
                  uiGmapPromises.GoogleMapsIsAllReady()
                    .then(function(theMap) {

                      $scope.map.window.options.pixelOffset = new GoogleMaps.Size(0, -50);
                      fitBoundsWithCurrentMarkers(theMap, GoogleMaps);

                    });
                });

            });

          })
        ;

      }
    ])
  ;

})();
